import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Header from './Components/Header/Header';
import OnboardingScreen from './OnboardingScreen/OnboardingScreen';
import { isMobile } from './utils';
import Home from './Components/Home/Home';
import Search from './Components/Search/Search'; // Import Search component
import Cart from './Components/Cart/Cart';
import Grocery from './Components/Grocery/Grocery';
import Profile from './Components/Profile/Profile';
import Food from './Components/Food/Food';
import Medicine from './Components/Medicine/Medicine';
import Vegetables from './Components/Vegetables/Vegetables';
import CategoryPage from './Components/category/CategoryPage'; 
import Register from './Components/Register/Register'; 
import PaymentForm from './Components/Payment/PaymentForm';
import OrderHistory from './Components/Orders/Orders';
import Login from './Components/Login/Login';

function App() {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Load cart items from localStorage on mount
    const savedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(savedCartItems);
  }, []);

  useEffect(() => {
    // Save cart items to localStorage when cartItems state changes
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const handleOnboardingComplete = () => {
    setShowOnboarding(true);
    localStorage.setItem('onboardingComplete', 'true');
  };

  const navigate = useNavigate();

  const handleCategoryChange = (category) => {
    if (category === "Food") {
      navigate("/food");
    } else if (category === "Grocery") {
      navigate("/grocery");
    } else if (category === "Medicine") {
      navigate("/medicine");
    }
  };

  // Add to cart function
  const addToCart = (item) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(cartItem => cartItem.name === item.name);
      if (existingItem) {
        // Update quantity if item already exists
        return prevItems.map(cartItem =>
          cartItem.name === item.name
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      } else {
        // Add new item to the cart
        return [...prevItems, { ...item, quantity: 1 }];
      }
    });
  };

  return (
    <div className="App">
      {showOnboarding ? (
        <OnboardingScreen onComplete={handleOnboardingComplete} />
      ) : (
        <>
          <Header cartItems={cartItems} onCategoryChange={handleCategoryChange} />
          <Routes>
            <Route path="/" element={<Home onCategoryChange={handleCategoryChange} />} />
            <Route path="/grocery" element={<Grocery cartItems={cartItems} setCartItems={setCartItems} />} />
            <Route path="/cart" element={<Cart cartItems={cartItems} setCartItems={setCartItems} />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/food" element={<Food cartItems={cartItems} setCartItems={setCartItems} />} />
            <Route path="/medicine" element={<Medicine cartItems={cartItems} setCartItems={setCartItems} />} />
            <Route path="/vegetables" element={<Vegetables cartItems={cartItems} setCartItems={setCartItems} />} />
            <Route path="/category/:category" element={<CategoryPage cartItems={cartItems} setCartItems={setCartItems} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/search/:searchTerm" element={<Search cartItems={cartItems} setCartItems={setCartItems} addToCart={addToCart} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/orders" element={<OrderHistory />} />
            <Route path="/payment" element={<PaymentForm />} />
          </Routes>
        </>
      )}
    </div>
  );
}

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
