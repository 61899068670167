import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Register from '../Register/Register';
import './Profile.css';
import config from '../config/config';

const Profile = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('profile');
  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserDetails();
  }, [navigate]);

  const fetchUserDetails = async () => {
    const email = localStorage.getItem('email');
    if (!email) {
      navigate('/login');
      return;
    }

    try {
      const userResponse = await axios.get(`${config.LOCAL_HOST}/api/users/email/${email}`);
      setUserDetails(userResponse.data);
      setEditedDetails(userResponse.data);
      
      // Fetch user orders (mock data for now)
      const mockOrders = [
        { id: '1', date: '2023-05-15', total: 99.99, status: 'Delivered' },
        { id: '2', date: '2023-05-20', total: 149.99, status: 'Shipped' },
        { id: '3', date: '2023-05-25', total: 79.99, status: 'Processing' },
      ];
      setOrders(mockOrders);
    } catch (error) {
      console.error('Error fetching user details:', error);
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    navigate('/login');
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setEditedDetails({ ...userDetails });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails({ ...editedDetails, [name]: value });
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axios.put(`${config.LOCAL_HOST}/api/users/email/${userDetails.email}`, editedDetails);
      if (response.status === 200) {
        setUserDetails(editedDetails);
        setIsEditing(false);
        alert('Profile updated successfully!');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!userDetails) {
    return <Register />;
  }

  return (
    <div className="profile-container">
      <div className="profile-sidebar">
        <h2>Your Account</h2>
        <ul>
          <li onClick={() => setActiveTab('profile')} className={activeTab === 'profile' ? 'active' : ''}>Profile</li>
          <li onClick={() => setActiveTab('orders')} className={activeTab === 'orders' ? 'active' : ''}>Your Orders</li>
          <li onClick={() => setActiveTab('addresses')} className={activeTab === 'addresses' ? 'active' : ''}>Addresses</li>
          <li onClick={() => setActiveTab('payment')} className={activeTab === 'payment' ? 'active' : ''}>Payment Options</li>
        </ul>
      </div>
      <div className="profile-content">
        {activeTab === 'profile' && (
          <div>
            <h2>Profile Information</h2>
            <div className="profile-info">
              {isEditing ? (
                <>
                  <p><strong>Name:</strong> <input name="name" value={editedDetails.name} onChange={handleInputChange} /></p>
                  <p><strong>Email:</strong> <input name="email" value={editedDetails.email} onChange={handleInputChange} /></p>
                  <p><strong>Phone:</strong> <input name="phone" value={editedDetails.phone} onChange={handleInputChange} /></p>
                </>
              ) : (
                <>
                  <p><strong>Name:</strong> {userDetails.name}</p>
                  <p><strong>Email:</strong> {userDetails.email}</p>
                  <p><strong>Phone:</strong> {userDetails.phone}</p>
                </>
              )}
            </div>
            {isEditing ? (
              <button className="save-button" onClick={handleSaveChanges}>Save Changes</button>
            ) : (
              <button className="edit-button" onClick={handleEditToggle}>Edit</button>
            )}
          </div>
        )}
        {activeTab === 'orders' && (
          <div>
            <h2>Your Orders</h2>
            <table className="orders-table">
              <thead>
                <tr>
                  <th>Order #</th>
                  <th>Date</th>
                  <th>Total</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {orders.map(order => (
                  <tr key={order.id}>
                    <td>{order.id}</td>
                    <td>{order.date}</td>
                    <td>${order.total.toFixed(2)}</td>
                    <td>{order.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {activeTab === 'addresses' && (
          <div>
            <h2>Your Addresses</h2>
            <p>Manage your shipping addresses here.</p>
            {/* Add address management functionality here */}
          </div>
        )}
        {activeTab === 'payment' && (
          <div>
            <h2>Payment Options</h2>
            <p>Manage your payment methods here.</p>
            
          </div>
        )}
      </div>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Profile;