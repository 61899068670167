const config = {
  SEARCH_API: [
    'https://run.mocky.io/v3/aa6e46e5-4274-4553-aad7-ee94b4d1aea5',
    'https://run.mocky.io/v3/2cfeba75-94af-43c2-8090-5d74335b534f'
  ],
  GROCERY_API_URL: 'https://run.mocky.io/v3/aa6e46e5-4274-4553-aad7-ee94b4d1aea5',
  FOOD_API_URL: 'https://run.mocky.io/v3/2cfeba75-94af-43c2-8090-5d74335b534f',
  MEDICINE_API_URL: 'https://run.mocky.io/v3/54c0c4b0-f619-4102-9a94-c3be9e291e87',
  LOCAL_HOST: 'http://192.168.246.241:8080'

};

export default config;

