import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './CategoryPage.css';
import config from '../config/config';
import axios from 'axios';

function CategoryPage({ cartItems, setCartItems }) {
  const { category } = useParams();
  const [grocery, setGrocery] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGrocery = async () => {
      try {
        const groceryResponse = await axios.get(config.GROCERY_API_URL);
        const groceryData = groceryResponse.data;

        const foodResponse = await axios.get(config.FOOD_API_URL);
        const foodData = foodResponse.data.data;

        const combinedGrocery = [
          ...groceryData.data,
          ...foodData 
        ];

        const filteredGrocery = combinedGrocery.filter(item => 
          category === 'all' ? true : item.category.toLowerCase() === category.toLowerCase()
        );

        setGrocery(filteredGrocery);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGrocery();
  }, [category]);

  const updateCartItems = (newCartItems) => {
    const updatedItems = typeof newCartItems === 'function' ? newCartItems(cartItems) : newCartItems;
    setCartItems(updatedItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));
  };

  const handleAddToCart = (product) => {
    updateCartItems((prevItems) => {
      const itemExists = prevItems.find(item => item.name === product.name);
      if (itemExists) {
        return prevItems.map(item =>
          item.name === product.name
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });
  };

  const handleIncrement = (product) => {
    updateCartItems((prevItems) =>
      prevItems.map(item =>
        item.name === product.name
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )
    );
  };

  const handleDecrement = (product) => {
    updateCartItems((prevItems) => {
      const existingItem = prevItems.find(item => item.name === product.name);
      if (existingItem.quantity > 1) {
        return prevItems.map(item =>
          item.name === product.name
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      } else {
        return prevItems.filter(item => item.name !== product.name);
      }
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="category-page">
      <h2>{category.charAt(0).toUpperCase() + category.slice(1)} Products</h2>
      <div className="category-grid-container">
        {grocery.length === 0 ? (
          <div>No products found in {category}</div>
        ) : (
          grocery.map((product) => (
            <div key={product.name} className="category-item">
              <div className="category-image-container">
                <img src={product.url} alt={product.name} className="category-image" />
              </div>
              <div className="product-details">
                <div>
                  <div className="category-name">{product.name}</div>
                  {product.size && <div className="category-size">({product.size})</div>}
                </div>
                <div>
                  <div className="category-price">{`₹${product.price}`}</div>
                  {cartItems.find(item => item.name === product.name) ? (
                    <div className="quantity-box">
                      <button className="decrement-button" onClick={() => handleDecrement(product)}>-</button>
                      <span className="quantity">{cartItems.find(item => item.name === product.name).quantity}</span>
                      <button className="increment-button" onClick={() => handleIncrement(product)}>+</button>
                    </div>
                  ) : (
                    <button className="add-to-cart-button" onClick={() => handleAddToCart(product)}>Add to Cart</button>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default CategoryPage;
