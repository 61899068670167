import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Food.css';
import axios from 'axios';
import config from '../config/config';


function FoodCategories({ categories, onSelectCategory }) {
  return (
    <div className="food-categories">
      <h2>Food Categories</h2>
      <div className="food-category-buttons">
        {categories.map((category) => (
          <button key={category} onClick={() => onSelectCategory(category)}>
            {category}
          </button>
        ))}
      </div>
    </div>
  );
}


function FoodGrid({ foods, selectedCategory, cartItems, updateCartItems }) {
  const navigate = useNavigate();

  const handleAddToCart = (food) => {
    updateCartItems((prevItems) => {
      const itemExists = prevItems.find(item => item.name === food.name);
      if (itemExists) {
        return prevItems.map(item =>
          item.name === food.name
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...food, quantity: 1 }];
      }
    });
  };

  const handleIncrement = (food) => {
    updateCartItems((prevItems) =>
      prevItems.map(item =>
        item.name === food.name
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )
    );
  };

  const handleDecrement = (food) => {
    updateCartItems((prevItems) => {
      const existingItem = prevItems.find(item => item.name === food.name);
      if (existingItem.quantity > 1) {
        return prevItems.map(item =>
          item.name === food.name
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      } else {
        return prevItems.filter(item => item.name !== food.name);
      }
    });
  };

  const foodCategories = foods.reduce((categories, food) => {
    if (!categories[food.category]) {
      categories[food.category] = [];
    }
    categories[food.category].push(food);
    return categories;
  }, {});

  if (!foodCategories[selectedCategory] && selectedCategory !== 'All') {
    return <div className="no-food-message">No food items are available.</div>;
  }

  return (
    <div className="food-grid">
      {Object.keys(foodCategories).map((category) => {
        if (selectedCategory !== 'All' && selectedCategory !== category) {
          return null;
        }

        const categoryFoods = foodCategories[category];

        return (
          <div key={category}>
            <div className="food-header">
              <h2>{category}</h2>
              <span onClick={() => navigate(`/category/${category.toLowerCase()}`)} className="show-more-link">
                Show More
              </span>
            </div>
            <div className="food-grid-container">
              {categoryFoods.slice(0, 5).map((food) => (
                <div key={food.name} className="food-item">
                  <img src={food.url} alt={food.name} className="food-image" />
                  <div className="food-name">
                    {food.name}
                  </div>
                  {food.size && (
                    <span className="food-size"> ({food.size})</span> 
                  )}
                  <div className="food-details">
                    <div className="food-price">{`₹${food.price}`}</div>
                    {cartItems.find(item => item.name === food.name) ? (
                      <div className="quantity-controls">
                        <button className="decrement-button" onClick={() => handleDecrement(food)}>-</button>
                        <span>{cartItems.find(item => item.name === food.name).quantity}</span>
                        <button className="increment-button" onClick={() => handleIncrement(food)}>+</button>
                      </div>
                    ) : (
                      <button className="add-to-cart-button" onClick={() => handleAddToCart(food)}> Cart</button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function Food({ cartItems, setCartItems }) {
  const [foods, setFoods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categories, setCategories] = useState([]);

  const updateCartItems = (newCartItems) => {
    const updatedItems = typeof newCartItems === 'function' ? newCartItems(cartItems) : newCartItems;
    setCartItems(updatedItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));
  };

  useEffect(() => {
    const fetchFoods = async () => {
      try {
        const response = await axios.get(config.FOOD_API_URL);
        const data = response.data.data;

        if (Array.isArray(data)) {
          setFoods(data);
          const uniqueCategories = ['All', ...new Set(data.map(food => food.category))];
          setCategories(uniqueCategories);
        } else {
          throw new Error('Unexpected data format');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFoods();
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    setCartItems(storedCartItems);

  }, [setCartItems]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="food-app">
      <FoodCategories categories={categories} onSelectCategory={setSelectedCategory} />
      <FoodGrid
        foods={foods}
        selectedCategory={selectedCategory}
        cartItems={cartItems}
        updateCartItems={updateCartItems}
      />
    </div>
  );
}

export default Food;
