import React, { useState } from 'react';
import './Register.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Box, Typography, TextField, Button, useMediaQuery, useTheme } from '@mui/material';
import { Person, Email, Lock, Phone, CheckCircleOutline } from '@mui/icons-material';
import config from '../config/config';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',  
    email: '',
    password: '',
    phone: '', 
    newsletter: false,
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name.trim()) formErrors.name = 'Name is required';
    if (!formData.email.trim()) formErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) formErrors.email = 'Email is invalid';
    if (!formData.password) formErrors.password = 'Password is required';
    else if (formData.password.length < 6) formErrors.password = 'Password must be at least 6 characters';
    if (!formData.phone.trim()) formErrors.phone = 'Mobile number is required';
    else if (!/^\d{10}$/.test(formData.phone)) formErrors.phone = 'Mobile number is invalid';
    
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      try {
        const response = await axios.post(`${config.LOCAL_HOST}/api/users/register`, formData);

        if (response.status === 200) {
          setSuccessMessage('Verification code sent. Please check your email.');
          setErrors({});
          setShowVerificationModal(true);
        }
      } catch (error) {
        setSuccessMessage('');
        if (error.response) {
          setErrors({ submit: error.response.data });
        } else {
          setErrors({ submit: 'Failed to sign up. Please try again.' });
        }
      }
    }
  };

  const handleVerificationSubmit = async () => {
    try {
      const response = await axios.post(`${config.LOCAL_HOST}/api/users/verify-otp`, {
        email: formData.email,
        otp: verificationCode
      });

      if (response.status === 200) {
        setShowVerificationModal(false);
        setSuccessMessage('Verification successful. Redirecting to login...');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    } catch (error) {
      setErrors({ verification: 'Invalid verification code. Please try again.' });
    }
  };

  const handleResendOTP = async () => {
    try {
      const response = await axios.post(`${config.LOCAL_HOST}/api/users/resend-otp`, {
        email: formData.email
      });

      if (response.status === 200) {
        setSuccessMessage('New verification code sent. Please check your email.');
        setErrors({});
      }
    } catch (error) {
      setErrors({ resend: 'Failed to resend verification code. Please try again.' });
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '90%' : 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: isMobile ? 2 : 4,
    borderRadius: 2,
    maxHeight: '90vh',
    overflow: 'auto',
  };
  return (
    <div className="signup-page" style={{marginTop: '50px'}}>
      <div className="signup-container">
        <div className="signup-form-container">
          <h1>Join Our Chelto Community</h1>
          <form className="signup-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <Person className="input-icon" />
              <input
                type="text"
                name="name"
                placeholder="Full name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>
            <div className="form-group">
              <Email className="input-icon" />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>

            <div className="form-group">
              <Phone className="input-icon" />
              <input
                type="tel"
                name="phone"
                placeholder="Mobile Number"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {errors.phone && <p className="error-message">{errors.phone}</p>}
            </div>
            <div className="form-group">
              <Lock className="input-icon" />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {errors.password && <p className="error-message">{errors.password}</p>}
            </div>
           

            {errors.submit && <p className="error-message">{errors.submit}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}

            <button type="submit" className="signup-button">Start Shopping Fresh</button>
            <p className="terms-text">
              By signing up, you agree to our <a href="#">Terms</a> and <a href="#">Privacy Policy</a>
            </p>
          </form>
          <div className="login-link">
            Already a member? <Link to='/login'>Log in to your account</Link>
          </div>
        </div>
        <div className="signup-image"></div>
      </div>


      <Modal
        open={showVerificationModal}
        onClose={() => setShowVerificationModal(false)}
        aria-labelledby="verification-modal"
      >
        <Box sx={modalStyle}>
          <Typography id="verification-modal" variant="h6" component="h2" sx={{ mb: 2 }}>
            Enter Verification Code
          </Typography>
          <TextField
            fullWidth
            type="text"
            label="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            margin="normal"
            sx={{ mb: 2 }}
          />
          {errors.verification && <Typography color="error" sx={{ mb: 2 }}>{errors.verification}</Typography>}
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2, mb: 2 }}>
            <Button 
              onClick={handleVerificationSubmit} 
              variant="contained" 
              color="primary"
              fullWidth={isMobile}
            >
              Verify
            </Button>
            <Button 
              onClick={handleResendOTP} 
              variant="outlined" 
              color="secondary"
              fullWidth={isMobile}
            >
              Resend OTP
            </Button>
          </Box>
          {errors.resend && <Typography color="error" sx={{ mb: 2 }}>{errors.resend}</Typography>}
          {successMessage && <Typography color="success" sx={{ mb: 2 }}>{successMessage}</Typography>}
        </Box>
      </Modal>
    </div>
  );
};

export default Register;
