
import React from 'react';
import { useState, useEffect } from 'react';
import Select from 'react-select';

const cities = [
  { value: 'Guntur', label: 'Guntur' },
  { value: 'Chilakaluripet', label: 'Chilakaluripet' },
  { value: 'Narsaraopet', label: 'Narsaraopet' },
  { value: 'Addanki', label: 'Addanki' },
  { value: 'Vinukonda', label: 'Vinukonda' }
];

const LocationModal = ({ onLocationSelect, onClose, initialLocation }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
    useEffect(() => {
      if (initialLocation) {
        const matchingCity = cities.find(city => city.value === initialLocation);
        setSelectedLocation(matchingCity || null);
      }
    }, [initialLocation]);
  
    const handleSelectChange = (selectedOption) => {
      setSelectedLocation(selectedOption);
      onLocationSelect(selectedOption.value);
      onClose();
    };

// const LocationModal = ({ onLocationSelect, onClose }) => {
//   const [selectedLocation, setSelectedLocation] = useState(null);
//   const handleSelectChange = (selectedOption) => {
//     setSelectedLocation(selectedOption);
//     onLocationSelect(selectedOption.value);
//     onClose();
//   };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderRadius: '10px',
      padding: '5px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      border: '1px solid #ff0156',
      fontSize: '1rem',
      color: '#333',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#ff0156' : '#ffffff',
      color: state.isFocused ? '#ffffff' : '#333',
      padding: '10px',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '10px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#333',
    }),
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '9999',
      }}
    >
      <div
        style={{
          background: 'rgba(255, 255, 255, 0.15)',
          backdropFilter: 'blur(1px)',
          padding: '40px',
          borderRadius: '25px',
          boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
          textAlign: 'center',
          maxWidth: '420px',
          width: '90%',
          position: 'relative',
        }}
      >
        <h2
          style={{
            fontSize: '2rem',
            marginBottom: '20px',
            color: '#ffffff',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '700',
          }}
        >
          Select Your Location
        </h2>
        <Select
          options={cities}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          onChange={handleSelectChange}
          styles={customStyles}
          value={selectedLocation}
        />
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            backgroundColor: '#ff0156',
            color: 'white',
            border: 'none',
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            fontSize: '1.5rem',
            cursor: 'pointer',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            transition: 'transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = '#ff9900';
            e.target.style.transform = 'scale(1.1)';
            e.target.style.boxShadow = '0 8px 15px rgba(0, 0, 0, 0.3)';
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = '#ffcc00';
            e.target.style.transform = 'scale(1)';
            e.target.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.2)';
          }}
        >
          ×
        </button>
      </div>
    </div>
  );
};

export default LocationModal;
