import React, { useEffect, useState } from 'react';
import './Vegetables.css'; // Ensure your CSS file is correctly linked

function Vegetables({ cartItems, setCartItems }) {
  const [vegetables, setVegetables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quantities, setQuantities] = useState({}); // Track quantities of items added to cart

  useEffect(() => {
    const fetchVegetables = async () => {
      try {
        const response = await fetch('https://run.mocky.io/v3/0248100a-6d35-4bfc-9343-5769a6b06201'); // Your API URL
        const data = await response.json();

        // Filter vegetables only
        const filteredVegetables = data.data.filter(item => item.category === 'Vegetable');

        // Include specific vegetables
        const specificVegetableNames = ['Tomatoes', 'Pumpkin', 'Cauliflower', 'Zucchini', 'Bell Pepper'];
        specificVegetableNames.forEach(name => {
          const vegetable = data.data.find(item => item.name === name);
          if (vegetable) {
            filteredVegetables.push(vegetable);
          }
        });

        setVegetables(filteredVegetables);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVegetables();
  }, []);

  const addToCart = (vegetable) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(item => item.name === vegetable.name);
      
      if (existingItem) {
        // If the item already exists, increment the quantity
        return prevItems.map(item => 
          item.name === vegetable.name 
            ? { ...item, quantity: item.quantity + 1 } 
            : item
        );
      } else {
        // If it doesn't exist, add it with a quantity of 1
        return [...prevItems, { ...vegetable, quantity: 1 }];
      }
    });

    // Update the local quantity state
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [vegetable.name]: (prevQuantities[vegetable.name] || 0) + 1 // Increment the quantity
    }));
  };

  const decrementQuantity = (vegetable) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(item => item.name === vegetable.name);

      if (existingItem && existingItem.quantity > 1) {
        // If quantity is more than 1, decrement the quantity
        return prevItems.map(item => 
          item.name === vegetable.name 
            ? { ...item, quantity: item.quantity - 1 } 
            : item
        );
      } else {
        // If quantity is 1, remove the item from the cart
        return prevItems.filter(item => item.name !== vegetable.name);
      }
    });

    // Update the local quantity state
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [vegetable.name]: Math.max((prevQuantities[vegetable.name] || 0) - 1, 0) // Decrement the quantity
    }));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="vegetables-grid">
      <h2>All Available Vegetables</h2>
      <div className="vegetables-grid-container">
        {vegetables.map((vegetable) => (
          <div key={vegetable.name} className="vegetables-item">
            <img src={vegetable.url} alt={vegetable.name} className="vegetables-image" />
            <div className="vegetables-name">{vegetable.name}</div>
            <div className="product-details">
              <div className="vegetables-price">₹{vegetable.price || "N/A"}</div>
              <div className="add-to-cart-container">
                {quantities[vegetable.name] > 0 ? (
                  <div className="quantity-box">
                    <span 
                      className="increment-button" 
                      onClick={() => addToCart(vegetable)}
                    >
                      + 
                    </span>
                    <span className="quantity">{quantities[vegetable.name]}</span>
                    <span 
                      className="decrement-button" 
                      onClick={() => decrementQuantity(vegetable)}
                    >
                      - 
                    </span>
                  </div>
                ) : (
                  <button 
                    className="add-to-cart-button"
                    onClick={() => addToCart(vegetable)} // Call addToCart on button click
                  >
                    Add to Cart
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Vegetables;