const dbName = 'OrdersDatabase';
const dbVersion = 1;
const storeName = 'orders';

const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);

    request.onerror = (event) => reject("IndexedDB error: " + event.target.error);

    request.onsuccess = (event) => resolve(event.target.result);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
    };
  });
};

export const saveOrder = async (order) => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.add(order);

    request.onerror = (event) => reject("Error saving order: " + event.target.error);
    request.onsuccess = (event) => resolve(event.target.result);
  });
};

export const getOrders = async () => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.getAll();

    request.onerror = (event) => reject("Error getting orders: " + event.target.error);
    request.onsuccess = (event) => resolve(event.target.result);
  });
};
