import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Box, Typography, TextField, Button, IconButton, Fade } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './Login.css';
import config from '../config/config';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [showOtpField, setShowOtpField] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${config.LOCAL_HOST}/api/users/login`, {
        email,
        password,
      });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('email', email);
        navigate('/grocery'); 
      }
    } catch (err) {
      setError(err.response?.data || 'An error occurred during login');
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${config.LOCAL_HOST}/api/users/forget-password`, { email: resetEmail });
      setShowOtpField(true);
      setError('OTP sent to your email. Please check and enter below.');
    } catch (error) {
      setError('Failed to send OTP. Please try again.');
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${config.LOCAL_HOST}/api/users/verify-forget-password-otp`, {
        email: resetEmail,
        otp: otp,
        newPassword: newPassword
      });
      setShowForgotPassword(false);
      setError('Password has been reset successfully. Please login with your new password.');
    } catch (error) {
      setError('Failed to verify OTP or reset password. Please try again.');
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '78%', sm: 400 },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  return (
    <div className="signin-page">
      <div className="signin-container">
        <div className="signin-form-container">
          <h1>Welcome to Chelto</h1>
          <form className="signin-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="signin-button" disabled={loading}>
              {loading ? 'Logging In...' : 'Log In'}
            </button>
            {error && <p className="error-message">{error}</p>}

            <p className="forgot-password">
              <a href="#" onClick={(e) => {
                e.preventDefault();
                setShowForgotPassword(true);
              }}>Forgot Password?</a>
            </p>
          </form>
          <div className="signup-link">
            Don't have an account? <Link to='/register'>Sign up</Link>
          </div>
        </div>
        <div className="signin-image"></div>
      </div>
      
      
      <Modal
        open={showForgotPassword}
        onClose={() => setShowForgotPassword(false)}
        aria-labelledby="forgot-password-modal"
        closeAfterTransition
      >
        <Fade in={showForgotPassword}>
          <Box sx={modalStyle}>
            <IconButton
              aria-label="close"
              onClick={() => setShowForgotPassword(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="forgot-password-modal" variant="h5" component="h2" sx={{ mb: 3, color: '#4CAF50' }}>
              Reset Password
            </Typography>
            {!showOtpField ? (
              <form onSubmit={handleForgotPassword}>
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  required
                  margin="normal"
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary" 
                  fullWidth
                  sx={{ 
                    mt: 2, 
                    bgcolor: '#4CAF50', 
                    '&:hover': { bgcolor: '#45a049' } 
                  }}
                >
                  Send OTP
                </Button>
              </form>
            ) : (
              <form onSubmit={handleVerifyOtp}>
                <TextField
                  fullWidth
                  type="text"
                  label="OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                  margin="normal"
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  type="password"
                  label="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  margin="normal"
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary" 
                  fullWidth
                  sx={{ 
                    mt: 2, 
                    bgcolor: '#4CAF50', 
                    '&:hover': { bgcolor: '#45a049' } 
                  }}
                >
                  Verify OTP and Reset Password
                </Button>
              </form>
            )}
            {error && (
              <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                {error}
              </Typography>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Login;