import React from 'react';
import './Invoice.css';
import html2pdf from 'html2pdf.js';

const Invoice = ({ order, shippingCharges }) => {
  const { orderId, customerName, customerAddress, city, mobileNumber, email, items, totalAmount, paymentDetails } = order;

  const calculateSubtotal = () => {
    return items.reduce((total, item) => total + parseFloat(item.price) * item.quantity, 0);
  };

  const formatPrice = (price) => {
    return typeof price === 'number' ? price.toFixed(2) : parseFloat(price).toFixed(2);
  };

  const subtotal = calculateSubtotal();
  const discount = totalAmount - subtotal - shippingCharges;

  const downloadInvoice = () => {
    const element = document.getElementById('invoice-content');
    const opt = {
      margin: 1,
      filename: `Invoice-${orderId}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save();
  };

  return (
    <div className="invoice-container">
      <div id="invoice-content">
        <section className="invoice-details">
          <div className="invoice-meta">
            <p><strong>Invoice ID:</strong> #{orderId}</p>
            <p><strong>Date:</strong> {new Date().toLocaleDateString()}</p>
          </div>

          <div className="customer-info">
            <h2>Customer Information</h2>
            <p><strong>Name:</strong> {customerName}</p>
            <p><strong>Address:</strong> {customerAddress}, {city}</p>
            <p><strong>Mobile:</strong> {mobileNumber}</p>
            <p><strong>Email:</strong> {email}</p>
          </div>
        </section>

        <table className="invoice-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Price (₹)</th>
              <th>Total (₹)</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{item.productName}</td>
                <td>{item.quantity}</td>
                <td>{formatPrice(item.price)}</td>
                <td>{formatPrice(parseFloat(item.price) * item.quantity)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <section className="invoice-summary">
          <div className="summary-row">
            <p>Subtotal:</p>
            <p>₹{formatPrice(subtotal)}</p>
          </div>
          <div className="summary-row">
            <p>Shipping Charges:</p>
            <p>₹{formatPrice(shippingCharges)}</p>
          </div>
          {discount > 0 && (
            <div className="summary-row">
              <p>Discount:</p>
              <p>₹{formatPrice(discount)}</p>
            </div>
          )}
          <div className="summary-row total">
            <p>Total Amount:</p>
            <p>₹{formatPrice(totalAmount)}</p>
          </div>
        </section>

        <section className="payment-details">
          <h2>Payment Details</h2>
          <p><strong>Payment ID:</strong> {paymentDetails.razorpayPaymentId}</p>
          <p><strong>Order ID:</strong> {paymentDetails.razorpayOrderId}</p>
        </section>

        <footer className="invoice-footer">
          <p>Thank you for your business!</p>
          <p><strong>Chelto Pvt. Ltd.</strong> | support@chelto.com</p>
        </footer>
      </div>
      <button onClick={downloadInvoice} className="download-button">Download Invoice</button>
    </div>
  );
};

export default Invoice;