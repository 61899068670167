import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config/config'; // Adjust the path to your config file
import './Search.css'; // Ensure you have your Search.css for styling

const Search = ({ cartItems, setCartItems }) => {
  const { searchTerm } = useParams();
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [relatedItems, setRelatedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState('');

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (!searchTerm) {
        setSearchResults([]);
        setRelatedItems([]);
        return;
      }

      try {
        setLoading(true);
        const groceryResponse = await axios.get(config.GROCERY_API_URL);
        const foodResponse = await axios.get(config.FOOD_API_URL);
        const allItems = [...groceryResponse.data.data, ...foodResponse.data.data];

        const filteredItems = allItems.filter(item =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        if (filteredItems.length > 0) {
          const matchedCategory = filteredItems[0].category;
          setCategory(matchedCategory);

          const relatedCategoryItems = allItems.filter(item =>
            item.category === matchedCategory && !filteredItems.some(result => result.name === item.name)
          );

          setRelatedItems(relatedCategoryItems);
        } else {
          setCategory('');
          setRelatedItems([]);
        }

        setSearchResults(filteredItems);
      } catch (err) {
        setError('Error fetching search results');
      } finally {
        setLoading(false);
      }
    };

    fetchSearchResults();
  }, [searchTerm]);

  // Check if an item is already in the cart
  const isInCart = (item) => {
    return cartItems.find(cartItem => cartItem.name === item.name);
  };

  // Add item to cart
  const addToCart = (item) => {
    setCartItems(prevItems => {
      const existingItem = prevItems.find(cartItem => cartItem.name === item.name);
      if (existingItem) {
        return prevItems.map(cartItem =>
          cartItem.name === item.name
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      } else {
        return [...prevItems, { ...item, quantity: 1 }];
      }
    });
  };

  // Handle decrementing item quantity
  const decrementQuantity = (item) => {
    setCartItems(prevItems => {
      const existingItem = prevItems.find(cartItem => cartItem.name === item.name);
      if (existingItem.quantity > 1) {
        return prevItems.map(cartItem =>
          cartItem.name === item.name
            ? { ...cartItem, quantity: cartItem.quantity - 1 }
            : cartItem
        );
      } else {
        return prevItems.filter(cartItem => cartItem.name !== item.name);
      }
    });
  };

  // Handle incrementing item quantity
  const incrementQuantity = (item) => {
    setCartItems(prevItems =>
      prevItems.map(cartItem =>
        cartItem.name === item.name
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      )
    );
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="search-results">
      <button className="back-button" onClick={handleGoBack}>Back</button>

      {searchResults.length > 0 ? (
        <>
          {category && <h3>Category: {category}</h3>}

          {searchResults.map(item => (
            <div key={item.name} className="search-item">
              <div className="item-image">
                <img src={item.url} alt={item.name} />
              </div>
              <div className="item-info">
                <div className="item-name">{item.name}</div>
                <div className="item-size">{item.size}</div>
                <div className="item-price">₹{item.price}</div>
                {isInCart(item) ? (
                  <div className="quantity-controls">
                    <button onClick={() => decrementQuantity(item)}>-</button>
                    <span>{cartItems.find(cartItem => cartItem.name === item.name).quantity}</span>
                    <button onClick={() => incrementQuantity(item)}>+</button>
                  </div>
                ) : (
                  <button className="add-to-cart-button" onClick={() => addToCart(item)}>Add to Cart</button>
                )}
              </div>
            </div>
          ))}

          {relatedItems.length > 0 && (
            <>
              <h3>Related items</h3>
              <div className="related-items">
                {relatedItems.map(item => (
                  <div key={item.name} className="related-item">
                    <div className="item-image">
                      <img src={item.url} alt={item.name} />
                    </div>
                    <div className="item-info">
                      <div className="item-name">{item.name}</div>
                      <div className="item-size">{item.size}</div>
                      <div className="item-price">₹{item.price}</div>
                      {isInCart(item) ? (
                        <div className="quantity-controls">
                          <button onClick={() => decrementQuantity(item)}>-</button>
                          <span>{cartItems.find(cartItem => cartItem.name === item.name).quantity}</span>
                          <button onClick={() => incrementQuantity(item)}>+</button>
                        </div>
                      ) : (
                        <button className="add-to-cart-button" onClick={() => addToCart(item)}>Add to Cart</button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <div>No products found for "{searchTerm}"</div>
      )}
    </div>
  );
};

export default Search;
