
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css'; 
import LocationModal from './LocationModal';

function HeroSection() {
  return (
    <div className="home-hero-section">
      <div className="home-hero-content">
        <h1>Don't miss amazing grocery deals</h1>
        <p>Sign up for the daily newsletter</p>
        <div className="home-newsletter-form">
          <input type="email" placeholder="Your email address" />
          <button>Subscribe</button>
        </div>
      </div>
      <div className="home-hero-image">
        <img
          src="https://cdn.pixabay.com/photo/2021/08/29/12/46/man-6583171_1280.png"
          alt="Grocery items"
        />
      </div>
    </div>
  );
}


function PromotionalImages({ onCategoryChange }) {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState({ Grocery: 0, Food: 0, Medicine: 0 });

  const images = {
    Grocery: [
     
      "/images/Grocery.jpeg",
      "/images/Grocery1.jpeg",
      "/images/Grocery2.jpg",
      "/images/Grocery3.jpg",
    ],
    Food: [
      "/images/Food.png",
      "/images/Food1.png",
      "/images/Food2.png",
      "/images/Food3.png",
    ],
    Medicine: [
      "/images/medicines_delivery_image.png",
      // "/images/medicine_image_2.png",
      // "/images/medicine_image_3.png",
      // "/images/medicine_image_4.png",
    ],
  };

  useEffect(() => {
    const intervals = {};
    Object.keys(images).forEach(category => {
      intervals[category] = setInterval(() => {
        setActiveIndex(prev => ({
          ...prev,
          [category]: (prev[category] + 1) % images[category].length
        }));
      }, 3000);
    });

    return () => {
      Object.values(intervals).forEach(clearInterval);
    };
  }, []);

  const handleCategoryClick = (category) => {
    onCategoryChange(category);
    navigate(`/${category.toLowerCase()}`);
  };

  return (
    <div className="home-promotional-images">
      {Object.entries(images).map(([category, categoryImages]) => (
        <div key={category} className="home-image-section" onClick={() => handleCategoryClick(category)}>
          <div className="image-carousel">
            {categoryImages.map((src, index) => (
              <img
                key={src}
                src={src}
                alt={`${category} Delivery ${index + 1}`}
                className={`home-image ${index === activeIndex[category] ? 'active' : ''}`}
              />
            ))}
          </div>
          <h3>{category} Delivery</h3>
        </div>
      ))}
    </div>
  );
}
function Home({ onCategoryChange }) {
  const [location, setLocation] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const storedLocation = localStorage.getItem('userLocation');
    if (storedLocation) {
      setLocation(storedLocation);
    } else {
      setShowModal(true);
    }
  }, []);

  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation);
    localStorage.setItem('userLocation', selectedLocation);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLocationClick = () => {
    setShowModal(true);
  };

  return (
    <div className="home-app">
      {showModal && (
        <LocationModal
          onLocationSelect={handleLocationSelect}
          onClose={handleCloseModal}
        />
      )}
      <HeroSection />
      <PromotionalImages onCategoryChange={onCategoryChange} />
    </div>
  );
}

export default Home;