import React, { useState, useEffect } from 'react';
import { getOrders } from '../../utils/indexedDB';
import './OrderHistory.css';

const OrderHistory = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const fetchedOrders = await getOrders();
                setOrders(fetchedOrders);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, []);

    return (
        <div className="order-history-container">
            <h2>Order History</h2>
            {orders.length === 0 ? (
                <p>No orders found.</p>
            ) : (
                <ul className="order-list">
                    {orders.map((order, index) => (
                        <li key={index} className="order-item">
                            <h3>Order {index + 1}</h3>
                            <p><strong>Customer Name:</strong> {order.customerName}</p>
                            <p><strong>Address:</strong> {order.customerAddress}</p>
                            <p><strong>Mobile Number:</strong> {order.mobileNumber}</p>
                            <p><strong>Email:</strong> {order.email}</p>
                            <p><strong>Payment Status:</strong> {order.paymentStatus}</p>
                            <p><strong>Total Amount:</strong> {order.totalAmount ? `₹${order.totalAmount.toFixed(2)}` : 'N/A'}</p>
                            <h4>Items:</h4>
                            <ul className="item-list">
                                {order.items && order.items.map((item, itemIndex) => (
                                    <li key={itemIndex}>
                                        {item.productName} - Quantity: {item.quantity} - Price: ₹{item.price.toFixed(2)}
                                    </li>
                                ))}
                            </ul>
                            {order.paymentDetails && (
                                <div>
                                    <p><strong>Order ID:</strong> {order.paymentDetails.orderId}</p>
                                    <p><strong>Razorpay Order ID:</strong> {order.paymentDetails.razorpayOrderId}</p>
                                    <p><strong>Razorpay Payment ID:</strong> {order.paymentDetails.razorpayPaymentId}</p>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default OrderHistory;