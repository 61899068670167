import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveOrder } from '../../utils/indexedDB';
import config from '../config/config';
import './PaymentForm.css';

const PaymentForm = ({ cartItems, totalAmount, discount, shippingCharges, onSuccessfulPayment }) => {
    const [order, setOrder] = useState({
        customerName: '',
        customerAddress: '',
        city: '',
        mobileNumber: '',
        email: '',
        totalAmount: 0,
        items: []
    });
    const [error, setError] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('pending');
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [invoiceUrl, setInvoiceUrl] = useState(null);

    useEffect(() => {
        setOrder(prevOrder => ({
            ...prevOrder,
            totalAmount: totalAmount,
            items: cartItems.map(item => ({
                productId: item.id,
                productName: item.name,
                quantity: item.quantity,
                price: item.price
            }))
        }));
    }, [cartItems, totalAmount]);

    const [location, setLocation] = useState(null);

    useEffect(() => {
        const storedLocation = localStorage.getItem('userLocation');
        if (storedLocation) {
          setLocation(storedLocation);
          setOrder(prevOrder => ({...prevOrder, city: storedLocation}));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'city') {
          setLocation(value);
        }
        setOrder(prevOrder => ({...prevOrder, [name]: value}));
    };

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const handlePaymentSuccess = async (response) => {
        try {
          const result = await axios.post(`${config.LOCAL_HOST}/api/orders/updatePaymentStatus`, {
            orderId: orderId,
            razorpayOrderId: response.razorpay_order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            paymentStatus: 'paid'
          });
          setPaymentStatus('success');
          setPaymentDetails(result.data);
      
          // Save duplicate order data to IndexedDB
          const orderToSave = {
            ...order,
            paymentStatus: 'paid',
            paymentDetails: {
              orderId: result.data.orderId,
              razorpayOrderId: response.razorpay_order_id,
              razorpayPaymentId: response.razorpay_payment_id
            },
            items: order.items.map(item => ({
              productName: item.productName,
              quantity: item.quantity,
              price: item.price
            })),
            paidAmount: order.totalAmount,
            mobileNumber: order.mobileNumber,
            address: order.customerAddress
          };
          await saveOrder(orderToSave);
      
          onSuccessfulPayment(orderToSave);
        } catch (error) {
          console.error('Error updating payment status:', error);
          setError('Error updating payment status. Please contact support.');
        }
      };
    const generateInvoice = async (orderDetails) => {
        try {
            const userResponse = await axios.get(`${config.LOCAL_HOST}/api/orders/by-email/${order.email}`);
            const userData = userResponse.data;

            const invoiceData = {
                orderId: orderDetails.orderId,
                customerName: userData.customerName || order.customerName,
                customerAddress: userData.customerAddress || order.customerAddress,
                customerCity: userData.city || order.city,
                customerEmail: userData.email || order.email,
                customerPhone: userData.mobileNumber || order.mobileNumber,
                items: order.items,
                totalAmount: order.totalAmount,
                discount: discount,
                shippingCharges: shippingCharges,
                paymentId: orderDetails.razorpayPaymentId
            };

            // Store invoice data in local storage
            localStorage.setItem('invoiceData', JSON.stringify(invoiceData));

            // Generate invoice content
            const invoiceContent = generateInvoiceContent(invoiceData);

            // Create a Blob with the invoice content
            const blob = new Blob([invoiceContent], { type: 'text/plain' });

            // Create a download link
            const url = URL.createObjectURL(blob);
            return url;
        } catch (error) {
            console.error('Error generating invoice:', error);
            return null;
        }
    };

    const generateInvoiceContent = (invoiceData) => {
        let content = `Invoice for Order #${invoiceData.orderId}\n\n`;
        content += `Customer Name: ${invoiceData.customerName}\n`;
        content += `Address: ${invoiceData.customerAddress}, ${invoiceData.customerCity}\n`;
        content += `Email: ${invoiceData.customerEmail}\n`;
        content += `Phone: ${invoiceData.customerPhone}\n\n`;
        content += `Items:\n`;
        invoiceData.items.forEach(item => {
            content += `${item.productName} - Quantity: ${item.quantity} - Price: ₹${(item.price * item.quantity).toFixed(2)}\n`;
        });
        content += `\nSubtotal: ₹${(invoiceData.totalAmount - invoiceData.shippingCharges + invoiceData.discount).toFixed(2)}\n`;
        content += `Shipping Charges: ₹${invoiceData.shippingCharges.toFixed(2)}\n`;
        content += `Discount: ₹${invoiceData.discount.toFixed(2)}\n`;
        content += `Total Amount: ₹${invoiceData.totalAmount.toFixed(2)}\n\n`;
        content += `Payment ID: ${invoiceData.paymentId}\n`;
        return content;
    };

    const displayRazorpay = async (orderId, amount) => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            setError('Razorpay SDK failed to load. Are you online?');
            return;
        }

        const options = {
            key: 'rzp_test_3s6UeJsb1hp5Ku',
            amount: amount * 100,
            currency: 'INR',
            name: 'Chelto',
            description: 'Test Transaction',
            order_id: orderId,
            handler: function (response) {
                handlePaymentSuccess(response);
            },
            modal: {
                ondismiss: async function () {
                    try {
                        await axios.post(`${config.LOCAL_HOST}/api/orders/updatePaymentStatus`, {
                            orderId: orderId,
                            razorpayOrderId: orderId,
                            paymentStatus: 'failed'
                        });
                    } catch (error) {
                        console.error('Error updating payment status:', error);
                        setError('Error updating payment status. Please contact support.');
                    }
                }
            },
            prefill: {
                name: order.customerName,
                email: order.email,
                contact: order.mobileNumber
            },
            theme: {
                color: '#3399cc'
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await axios.post(`${config.LOCAL_HOST}/api/orders/create`, order);
            console.log('Order created:', response.data);
            if (response.data && response.data.orderId && response.data.amount) {
                setOrderId(response.data.orderId);
                displayRazorpay(response.data.orderId, response.data.amount);
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            console.error('Error creating order:', error);
            setError('Error creating order. Please try again.');
        }
    };

    if (paymentStatus === 'success') {
        return (
            <div className="payment-success">
                <h2>Payment Successful!</h2>
                <p>Thank you for your order, {order.customerName}.</p>
                <h3>Order Details:</h3>
                <p>Order ID: {paymentDetails.orderId}</p>
                <p>Payment ID: {paymentDetails.razorpayPaymentId}</p>
                <h4>Ordered Items:</h4>
                <ul>
                    {order.items.map((item, index) => (
                        <li key={index}>
                            {item.productName} (ID: {item.productId}) - Quantity: {item.quantity} - Price: ₹{(item.price * item.quantity).toFixed(2)}
                        </li>
                    ))}
                </ul>
                <p>Subtotal: ₹{(order.totalAmount - shippingCharges + discount).toFixed(2)}</p>
                <p>Shipping Charges: ₹{shippingCharges.toFixed(2)}</p>
                <p>Discount: ₹{discount.toFixed(2)}</p>
                <p>Total Amount: ₹{order.totalAmount.toFixed(2)}</p>
                {invoiceUrl && (
                    <a href={invoiceUrl} download={`invoice_${paymentDetails.orderId}.txt`}>
                        Download Invoice
                    </a>
                )}
            </div>
        );
    }

    return (
        <div className="payment-form-container">
            <h2>Place Your Order</h2>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleSubmit} className="payment-form">
                <div className="form-group">
                    <label htmlFor="customerName">Name</label>
                    <input
                        type="text"
                        id="customerName"
                        name="customerName"
                        value={order.customerName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="customerAddress">Address</label>
                    <input
                        type="text"
                        id="customerAddress"
                        name="customerAddress"
                        value={order.customerAddress}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="city">City</label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        value={order.city || ''}
                        onChange={handleChange}
                        required
                    />
                    {order.city ? (
                        <p>Your selected location is: {order.city}</p>
                    ) : (
                        <p>No location selected.</p>
                    )}
                </div>
                              <div className="form-group">
                                  <label htmlFor="mobileNumber">Mobile Number</label>
                                  <input
                                      type="tel"
                                      id="mobileNumber"
                                      name="mobileNumber"
                                      value={order.mobileNumber}
                                      onChange={handleChange}
                                      required
                                  />
                              </div>
                              <div className="form-group">
                                  <label htmlFor="email">Email</label>
                                  <input
                                      type="email"
                                      id="email"
                                      name="email"
                                      value={order.email}
                                      onChange={handleChange}
                                      required
                                  />
                              </div>
                              <div className="order-summary">
                                  <h3>Order Summary</h3>
                                  <ul>
                                      {order.items.map((item, index) => (
                                          <li key={index}>
                                              {item.productName} (ID: {item.productId}) - Quantity: {item.quantity} - Price: ₹{(item.price * item.quantity).toFixed(2)}
                                          </li>
                                      ))}
                                  </ul>
                                  <p>Subtotal: ₹{(order.totalAmount - shippingCharges + discount).toFixed(2)}</p>
                                  <p>Shipping Charges: ₹{shippingCharges.toFixed(2)}</p>
                                  <p>Discount: ₹{discount.toFixed(2)}</p>
                                  <p><strong>Total Amount: ₹{order.totalAmount.toFixed(2)}</strong></p>
                              </div>
                              <button type="submit" className="submit-button">Place Order</button>
                          </form>
                      </div>
                  );
              };
              
              export default PaymentForm;