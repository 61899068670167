import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Grocery.css';
import axios from 'axios';
import config from '../config/config';

function ProductCategories({ categories, onSelectCategory }) {
  return (
    <div className="grocery-categories">
      <h2>Grocery Categories</h2>
      <div className="grocery-category-buttons">

        {categories.map((category) => (
          <button key={category} onClick={() => onSelectCategory(category)}>
            {category}
          </button>
        ))}
      </div>
    </div>
  );
}

function GroceryGrid({ grocery, selectedCategory, cartItems, updateCartItems }) {
  const navigate = useNavigate();

  const handleAddToCart = (product) => {
    updateCartItems((prevItems) => {
      const itemExists = prevItems.find(item => item.name === product.name);
      if (itemExists) {
        return prevItems.map(item =>
          item.name === product.name
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });
  };

  const handleIncrement = (product) => {
    updateCartItems((prevItems) =>
      prevItems.map(item =>
        item.name === product.name
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )
    );
  };

  const handleDecrement = (product) => {
    updateCartItems((prevItems) => {
      const existingItem = prevItems.find(item => item.name === product.name);
      if (existingItem.quantity > 1) {
        return prevItems.map(item =>
          item.name === product.name
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      } else {
        return prevItems.filter(item => item.name !== product.name);
      }
    });
  };

  const productCategories = grocery.reduce((categories, product) => {
    if (!categories[product.category]) {
      categories[product.category] = [];
    }
    categories[product.category].push(product);
    return categories;
  }, {});

  if (!productCategories[selectedCategory] && selectedCategory !== 'All') {
    return <div className="no-grocery-message">No grocery are available.</div>;
  }

  return (
    <div className="grocery-grid">
      {Object.keys(productCategories).map((category) => {
        if (selectedCategory !== 'All' && selectedCategory !== category) {
          return null;
        }

        const categoryGrocery = productCategories[category];

        return (
          <div key={category}>
            <div className="grocery-header">
              <h2>{category}</h2>
              <span onClick={() => navigate(`/category/${category.toLowerCase()}`)} className="show-more-link">
                Show More
              </span>
            </div>
            <div className="grocery-grid-container">
              {categoryGrocery.slice(0, 5).map((product) => (
                <div key={product.name} className="grocery-item">
                  <img src={product.url} alt={product.name} className="grocery-image" />
                  <div className="grocery-name">
                    {product.name}
                  </div>
                  
                  {product.size && (
                    <div className="product-size">
                      {` ${product.size}`}
                    </div>
                  )}
                  <div className="grocery-details">
                    <div className="grocery-price">{`₹${product.price}`}</div>
                    {cartItems.find(item => item.name === product.name) ? (
                      <div className="quantity-controls">
                        <button className="decrement-button" onClick={() => handleDecrement(product)}>-</button>
                        <span>{cartItems.find(item => item.name === product.name).quantity}</span>
                        <button className="increment-button" onClick={() => handleIncrement(product)}>+</button>
                      </div>
                    ) : (
                      <button className="add-to-cart-button" onClick={() => handleAddToCart(product)}> Cart</button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function Grocery({ cartItems, setCartItems }) {
  const [grocery, setGrocery] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categories, setCategories] = useState([]);

  const updateCartItems = (newCartItems) => {
    const updatedItems = typeof newCartItems === 'function' ? newCartItems(cartItems) : newCartItems;
    setCartItems(updatedItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));
  };

  useEffect(() => {
    const fetchGrocery = async () => {
      try {
        const response = await axios.get(config.GROCERY_API_URL);
        const data = response.data.data;
        localStorage.setItem('grocery', JSON.stringify(data));

        if (Array.isArray(data)) {
          setGrocery(data);
          const uniqueCategories = ['All', ...new Set(data.map(product => product.category))];
          setCategories(uniqueCategories);
        } else {
          throw new Error('Unexpected data format');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGrocery();

    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(storedCartItems);
  }, [setCartItems]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="grocery-app">
      <ProductCategories categories={categories} onSelectCategory={setSelectedCategory} />
      <GroceryGrid
        grocery={grocery}
        selectedCategory={selectedCategory}
        cartItems={cartItems}
        updateCartItems={updateCartItems}
      />
    </div>
  );
}


export default Grocery;

