import React from 'react';
import './OnboardingScreen.css';
import Logo from '../assets/images/logo.png'

const OnboardingScreen = ({ onComplete }) => {
  return (
    <div className="onboarding-screen">
      <div className="onboarding-content">
        <img 
          src={Logo}
          alt="GrocerEase Logo" 
          className="onboarding-image"
        />
        <p className="slogan">Your one-stop solution for effortless grocery shopping</p>
        <button className="get-started-button" onClick={onComplete}>Get Started</button>
      </div>
    </div>
  );
};

export default OnboardingScreen;