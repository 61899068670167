import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './Header.css';
import Logo from '../../assets/images/logo.png';
import LocationModal from '../Home/LocationModal';
import config from '../config/config'; 

const Header = ({ cartItems = [], onCategoryChange }) => {
  const [searchTerm, setSearchTerm] = useState(''); 
  const [searchResults, setSearchResults] = useState([]); 
  const [showResults, setShowResults] = useState(false); 
  const [showOverlay, setShowOverlay] = useState(false); 
  const navigate = useNavigate(); 
  const location = useLocation(); 
  const [showLocationModal, setShowLocationModal] = useState(false); 
  const [locationState, setLocationState] = useState(null); 

  const searchContainerRef = useRef(null); // Reference for the search container

  // Get location from localStorage if previously set
  useEffect(() => {
    const storedLocation = localStorage.getItem('userLocation');
    if (storedLocation) {
      setLocationState(storedLocation);
    }
  }, []);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm.trim().length >= 1) {
        try {
          const responses = await Promise.all([
            axios.get(config.GROCERY_API_URL),
            axios.get(config.FOOD_API_URL),
          ]);

          const allItems = responses.flatMap(response => response.data.data);

          const filteredResults = allItems.filter(item =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
          );
          setSearchResults(filteredResults); 
          setShowResults(true); 
          setShowOverlay(true); 
        } catch (error) {
          console.error('Error fetching search results:', error);
          setShowResults(false); 
          setShowOverlay(false); 
        }
      } else {
        setSearchResults([]);
        setShowResults(false); 
        setShowOverlay(false); 
      }
    };

    fetchSearchResults();
  }, [searchTerm]); // Runs whenever searchTerm changes

  useEffect(() => {
    setShowResults(false); 
    setShowOverlay(false); 
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        setShowResults(false); 
        setShowOverlay(false); 
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    console.log('Location state updated:', locationState);
  }, [locationState]);


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); 
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search/${searchTerm}`);
      setShowResults(false); 
      setShowOverlay(false); 
    }
  };

  const handleResultClick = (result) => {
    setShowResults(false); 
    setShowOverlay(false); 
 
    navigate(`/search/${result.name}`, { state: { selectedItem: result, category: result.category } });
  };

  const handleLocationClick = () => {
    setShowLocationModal(true);
  };

  const handleLocationSelect = (selectedLocation) => {
    setLocationState((prevState) => {
      localStorage.setItem('userLocation', selectedLocation);
      return selectedLocation;
    });
    setShowLocationModal(false);
  };

  const handleCloseLocationModal = () => {
    setShowLocationModal(false);
  };

  return (
    <header>
      <div className='container-fluid'>
        <div className='header-content'>
          <div className='logo-search-container'>
            <div className='logo-container'>
              <Link to="/" className="navbar-logo">
                <img src={Logo} alt='Logo' className="logo" />
              </Link>
            </div>
            <div className='search-container' ref={searchContainerRef}>
              <form onSubmit={handleSearchSubmit}>
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onFocus={() => setShowResults(true)} 
                />
                <button type="submit">
                  <i className="fas fa-search"></i>
                </button>
              </form>
              {showResults && searchResults.length > 0 && (
                <ul className="search-results-dropdown">
                  {searchResults.map((result) => (
                    <li
                      key={result.id}
                      onClick={() => handleResultClick(result)} 
                      className="search-result-item"
                    >
                      <img src={result.url} alt={result.name} className="search-result-image" /> {/* Display item image */}
                      <div className="search-result-details">
                        <div>{result.name}</div> 
                        <div>{result.size}</div>
                        <div>₹{result.price}</div> 
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="location-container">
            <button id="location-button" onClick={handleLocationClick}>
              <i className="fas fa-map-marker-alt"></i>
             <span id="location-name">
  {locationState || localStorage.getItem('userLocation') || 'Select Location'}
</span>
            </button>
          </div>
          <nav className='navigation-container desktop-only'>
            <ul>
              <li><NavLink exact to="/" activeClassName="active"><i className="fas fa-home"></i> Home</NavLink></li>
              <li><NavLink to="/grocery" activeClassName="active"><i className="fas fa-shopping-basket"></i> Grocery</NavLink></li>
              <li><NavLink to="/food" activeClassName="active"><i className="fas fa-utensils"></i> Food</NavLink></li>
              <li><NavLink to="/medicine" activeClassName="active"><i className="fas fa-pills"></i> Medicine</NavLink></li>
              <li><NavLink to="/cart" activeClassName="active"><i className="fas fa-shopping-cart"></i> Cart {cartItems.length > 0 ? `(${cartItems.length})` : ''}</NavLink></li>
              <li><NavLink to="/profile" activeClassName="active"><i className="fas fa-user"></i> Profile</NavLink></li>
            </ul>
          </nav>
        </div>
      </div>
      <nav className='mobile-nav'>
        <ul>
          <li><NavLink exact to="/" activeClassName="active"><i className="fas fa-home"></i> Home</NavLink></li>
          <li><NavLink to="/grocery" activeClassName="active"><i className="fas fa-shopping-basket"></i> Grocery</NavLink></li>
          <li><NavLink to="/food" activeClassName="active"><i className="fas fa-utensils"></i> Food</NavLink></li>
          <li><NavLink to="/medicine" activeClassName="active"><i className="fas fa-pills"></i> Medicine</NavLink></li>
          <li><NavLink to="/cart" activeClassName="active"><i className="fas fa-shopping-cart"></i> Cart {cartItems.length > 0 ? `(${cartItems.length})` : ''}</NavLink></li>
          <li><NavLink to="/profile" activeClassName="active"><i className="fas fa-user"></i> Profile</NavLink></li>
        </ul>
      </nav>
      {showLocationModal && (
        <LocationModal
          onLocationSelect={handleLocationSelect}
          onClose={handleCloseLocationModal}
          initialLocation={locationState}
        />
      )}
      
      {/* Background overlay */}
      {showOverlay && <div className="background-overlay" onClick={() => setShowResults(false)}></div>}
    </header>
  );
}

export default Header;
