import React, { useEffect, useState } from 'react';
import './Cart.css';
import PaymentForm from '../Payment/PaymentForm';
import { getOrders } from '../../utils/indexedDB';
import config from '../config/config';
import Invoice from '../Invoice/Invoice';

function Cart({ cartItems, setCartItems }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState('₹');
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState('');
  const [discount, setDiscount] = useState(0);
  const [couponApplied, setCouponApplied] = useState(false);
  const [lastOrder, setLastOrder] = useState(null);

  const SHIPPING_CHARGE = 35;

  // Function to update cart items and save them to localStorage
  const updateCartItems = (newCartItems) => {
    const updatedItems = typeof newCartItems === 'function' ? newCartItems(cartItems) : newCartItems;
    setCartItems(updatedItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedItems));
  };

  const clearCart = async () => {
    setCartItems([]);
    localStorage.removeItem('cartItems');
    setPaymentSuccessful(true);
    setShowPaymentForm(false);

    try {
      const orders = await getOrders();
      console.log('All orders:', orders);
      if (orders.length > 0) {
        setLastOrder(orders[orders.length - 1]);
      }
    } catch (error) {
      console.error('Error retrieving orders:', error);
    }
  };

  // Load cart items from localStorage when the component mounts
  useEffect(() => {
    const savedCartItems = localStorage.getItem('cartItems');
    if (savedCartItems) {
      setCartItems(JSON.parse(savedCartItems));
    }
    setLoading(false);
  }, [setCartItems]);

  const removeFromCart = (itemToRemove) => {
    updateCartItems((prevItems) => prevItems.filter(item => item.name !== itemToRemove.name));
  };

  const incrementQuantity = (itemToIncrement) => {
    updateCartItems((prevItems) => {
      return prevItems.map(item =>
        item.name === itemToIncrement.name
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
    });
  };

  const decrementQuantity = (itemToDecrement) => {
    updateCartItems((prevItems) => {
      const existingItem = prevItems.find(item => item.name === itemToDecrement.name);
      if (existingItem && existingItem.quantity > 1) {
        return prevItems.map(item =>
          item.name === itemToDecrement.name
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      } else {
        return prevItems.filter(item => item.name !== itemToDecrement.name);
      }
    });
  };

  const calculateSubtotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const calculateTotalPrice = () => {
    const subtotal = calculateSubtotal();
    const totalWithShippingAndDiscount = subtotal + SHIPPING_CHARGE - discount;
    return Number(totalWithShippingAndDiscount.toFixed(2));
  };

  const handleProceedToPayment = () => {
    setShowPaymentForm(true);
  };

  const validateCoupon = async () => {
    try {
      console.log(`Validating coupon: ${couponCode}`);
      const response = await fetch(`${config.LOCAL_HOST}/coupons/${couponCode}`);

      if (response.ok) {
        const amount = await response.json();
        console.log('Coupon valid, discount applied:', amount);
        setDiscount(amount);
        setCouponError('');
        setCouponApplied(true);
      } else {
        const errorMessage = await response.text();
        console.error('Error from API:', errorMessage);
        setCouponError(errorMessage);
        setCouponApplied(false);
        setDiscount(0);
      }
    } catch (error) {
      console.error('Error validating coupon:', error);
      setCouponError('Error validating coupon');
      setCouponApplied(false);
      setDiscount(0);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  if (paymentSuccessful) {
    return <Invoice
      order={lastOrder}
      shippingCharges={SHIPPING_CHARGE}
    />;
  }

  if (cartItems.length === 0) {
    return <div className="empty-cart-message">Your cart is empty.</div>;
  }

  if (showPaymentForm) {
    return <PaymentForm
      cartItems={cartItems}
      totalAmount={calculateTotalPrice()}
      discount={discount}
      shippingCharges={SHIPPING_CHARGE}
      onSuccessfulPayment={(order) => {
        setLastOrder(order);
        clearCart();
      }}
    />
  }

  return (
    <div className="cart-page" style={{ marginTop: '100px' }}>
      <div className="cart-section">
        <h2>Cart</h2>
        <div className="cart-items">
          {cartItems.map((item) => (
            <div key={item.name} className="cart-item">
              <img src={item.url} alt={item.name} className="cart-item-image" />
              <div className="cart-item-details">
                <span className="item-name">{item.name}</span>
                <div className="quantity-controls">
                  <button className="decrement-button" onClick={() => decrementQuantity(item)}>-</button>
                  <span>{item.quantity}</span>
                  <button className="increment-button" onClick={() => incrementQuantity(item)}>+</button>
                </div>
                <span className="item-price">{currencySymbol}{item.price}</span>
                <button className="remove-button" onClick={() => removeFromCart(item)}>🗑</button>
              </div>
            </div>
          ))}
        </div>
        <div className="cart-summary">
          <p>Subtotal: <span>{currencySymbol}{calculateSubtotal().toFixed(2)}</span></p>
          <p>Shipping Charge: <span>{currencySymbol}{SHIPPING_CHARGE.toFixed(2)}</span></p>
          {couponApplied && (
            <p>Discount: <span>{currencySymbol}{discount.toFixed(2)}</span></p>
          )}
          <h3>Total: <span>{currencySymbol}{calculateTotalPrice()}</span></h3>
        </div>

        <div className="apply-coupon">
          <input
            type="text"
            placeholder="Enter Coupon code"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <button onClick={validateCoupon}>Apply</button>
          {couponError && <div className="coupon-error">{couponError}</div>}
        </div>
      </div>
      <div className="order-summary">
        <button className="proceed-to-payment-button" onClick={handleProceedToPayment}>
          Proceed to Payment
        </button>
      </div>
    </div>
  );
}

export default Cart;